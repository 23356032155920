/* eslint-disable no-unused-vars,arrow-body-style */
import client from "../client";
import { outageCentreClient } from "../client/client";
import { mapPlannedOutage } from "../utils/IcpsServiceUtil";

export const ENDPOINT = "outagereporter/icp-locations";
export const OUTAGE_CENTRE_ENDPOINT = "v1/outages/icp-locations";

export class IcpsService {
  static getIcpStatus = async icpNumber => {
    // return ICP_STATUS[1];

    const { data } = await client.get(`${ENDPOINT}/${icpNumber}/status`);

    try {
      // outage centre client call for planned outage
      const response = await outageCentreClient.get(
        `/${OUTAGE_CENTRE_ENDPOINT}/${icpNumber}/planned-outages/current`,
      );

      if (response.status === 200) {
        data.outage = mapPlannedOutage(data.outage, response.data);
      }
    } catch (error) {
      return data;
    }

    return data;
  };
}
