/* istanbul ignore file */
import { setAutoFreeze } from "immer";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

import client from "../client";
import {
  authResponseInterceptor,
  errorResponseInterceptor,
} from "../client/interceptors";

import { rootReducer } from "./modules";
import { rootSaga } from "./sagas";

import {
  axiosInterceptorConfiguration,
  axiosApiConfiguration,
} from "./middleware/axios.middleware";
import { outageCentreClient } from "../client/client";

// Set Immer (immutability lib) to be compatible with redux-persist
setAutoFreeze(false);

const composeEnhancers = composeWithDevTools({ trace: true });
const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  sagaMiddleware,
  axiosApiConfiguration(client, window.config.apikey, window.config.apiBaseUrl),
  axiosInterceptorConfiguration(client, {
    response: [authResponseInterceptor, errorResponseInterceptor],
  }),
  axiosApiConfiguration(
    outageCentreClient,
    window.config.outageCentreApikey,
    window.config.outageCentreBaseUrl,
  ),
  axiosInterceptorConfiguration(outageCentreClient, {
    response: [authResponseInterceptor, errorResponseInterceptor],
  }),
];

const persistConfig = {
  key: "root",
  storage: storageSession,
  // empty whitelist means nothing is cached, this gets superseded by local persistors (config slice)
  whitelist: [],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  // Support Redux Devtools Chrome/Firefox Extension
  composeEnhancers(applyMiddleware(...middlewares)),
);
export const persistor = persistStore(store);
// persistor.purge();
sagaMiddleware.run(rootSaga, client);
