import moment from "moment";

export const getEarliestPlannedOutageBlockEtrStart = (
  useAlternativeDates,
  plannedOutageBlocks,
) => {
  const startDates = plannedOutageBlocks
    .map(pob =>
      useAlternativeDates
        ? pob.alternativeStartDateTime
        : pob.advertisedStartDateTime,
    )
    .map(dateTime => moment(dateTime));
  return moment.min(startDates);
};

export const getLatestPlannedOutageBlockEtrEnd = (
  useAlternativeDates,
  plannedOutageBlocks,
) => {
  const endDates = plannedOutageBlocks
    .map(pob =>
      useAlternativeDates
        ? pob.alternativeEndDateTime
        : pob.advertisedEndDateTime,
    )
    .map(dateTime => moment(dateTime));

  return moment.max(endDates);
};

export const mapPlannedOutage = (oldStatus, outageCentrePlannedOutage) => {
  const endDate = getLatestPlannedOutageBlockEtrEnd(
    outageCentrePlannedOutage.useAlternativeDates,
    outageCentrePlannedOutage.plannedOutageBlocks,
  );

  const now = moment(Date.now());

  // eslint-disable-next-line no-param-reassign
  return {
    id: oldStatus.id,
    address: oldStatus.address,
    eventType: "Planned",
    etrStart: getLatestPlannedOutageBlockEtrEnd(
      outageCentrePlannedOutage.useAlternativeDates,
      outageCentrePlannedOutage.plannedOutageBlocks,
    )
      .subtract(1, "hours")
      .toISOString(),
    etrEnd: endDate.toISOString(),
    outageStart: getEarliestPlannedOutageBlockEtrStart(
      outageCentrePlannedOutage.useAlternativeDates,
      outageCentrePlannedOutage.plannedOutageBlocks,
    ).toISOString(),
    workStatus: [
      {
        label: "Work starts",
        type: "START",
        complete: true,
        active: false,
      },
      {
        label: "Planned maintenance",
        type: "WORKING",
        complete: now > endDate,
        active: now < endDate,
      },
      {
        label: "Work finishes",
        type: "RESOLVED",
        complete: now > endDate,
        active: false,
      },
    ],
    outageResolvedFlag: oldStatus.outageResolvedFlag,
    transformerLongitude: oldStatus.transformerLongitude,
    transformerLatitude: oldStatus.transformerLatitude,
    outageId: outageCentrePlannedOutage.switchingProposalNumber,
    updatedAt: outageCentrePlannedOutage.updatedAt,
    subscription: oldStatus.subscription,
    progressionStatus: now < endDate ? "ACTIVE" : "RESOLVED",
    outageReason: outageCentrePlannedOutage.changeReason.reasonDescription,
    outageReasonDescription:
      outageCentrePlannedOutage.changeReason.reasonDescription,
    holdingMessage: oldStatus.holdingMessage,
  };
};
