import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components/macro";
import { color, fontSize, textAlign } from "styled-system";
import { ifProp } from "styled-tools";
import { getIn } from "formik";
import { focusStyling } from "../../common/styles";
import { withGATracking } from "../../common/withGATracking";

/* eslint-disable react/prop-types, no-shadow */
const Base = ({
  children,
  type,
  fontSize,
  textAlign,
  color,
  isSelected,
  ...props
}) => {
  const attrs = {
    type,
  };

  return React.createElement(
    "label",
    {
      ...attrs,
      ...props,
    },
    children,
  );
};

const Input = styled.input`
  display: inline-block;
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
`;

const Label = styled(Base)`
  appearance: none;
  background: none;
  cursor: pointer;
  font-weight: normal;
  padding: 20px 20px;
  border-radius: 4px;
  background-color: ${p => p.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.dark};
  text-decoration: none;
  ${fontSize};
  ${textAlign};
  ${color};
  display: block;
  width: 100%;
  ${focusStyling};

  ${ifProp(
    "isSelected",
    css`
      background: ${props => props.theme.colors.accent};
      color: ${props => props.theme.colors.white};
    `,
  )};
`;

export const RadioButton = ({
  field,
  form: { values },
  label,
  labelProps,
  ...props
}) => {
  const isSelected = field.value === getIn(values, field.name);

  return (
    <Label
      textAlign="center"
      fontSize={2}
      color="primary"
      isSelected={isSelected}
      {...labelProps}
    >
      <Input {...props} {...field} checked={isSelected} />
      {label}
    </Label>
  );
};

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  labelProps: PropTypes.object,
};
RadioButton.defaultProps = {
  labelProps: {},
};

export default withGATracking(RadioButton);
