import React from "react";
import PropTypes from "prop-types";
import { Flex, Text } from "rebass";
import { Button } from "../../Button";
import { usePlannedOutageDateToggle } from "../usePlannedOutageDateToggle";
import PlannedOutageEventInfo from "./PlannedOutageEventInfo";
import PlannedOutageDatesTable from "./PlannedOutageDatesTable";

const PlannedOutageDates = ({ plannedOutage }) => {
  const [
    toggleButtonLabel,
    showToggleButton,
    toggleDates,
    dateTimes,
  ] = usePlannedOutageDateToggle(plannedOutage);

  return (
    <Flex
      mt={3}
      flexDirection="column"
      justifyContent="flex-start"
      flex="1 1 auto"
    >
      <PlannedOutageEventInfo
        label="outage date(s)"
        fontWeight="bold"
        contentMargin={1}
      >
        <PlannedOutageDatesTable outageDates={dateTimes} />

        {showToggleButton && (
          <Button
            variant="text"
            lineHeight={1.2}
            mt={1}
            px={0}
            onClick={() => toggleDates()}
            data-testid="toggle-button"
          >
            <Text as="span" fontSize={0}>
              {toggleButtonLabel}
            </Text>
          </Button>
        )}
      </PlannedOutageEventInfo>
    </Flex>
  );
};

PlannedOutageDates.propTypes = {
  plannedOutage: PropTypes.object.isRequired,
};

export default PlannedOutageDates;
