/* eslint-disable no-unused-vars,arrow-body-style */
import { outageCentreClient } from "../client/client";
import mockResponse from "./__fixture__/planned-outages";

export const ALL_PLANNED_OUTAGES_ENDPOINT =
  "/v1/outages/planned-outages/future";
export const ICP_PLANNED_OUTAGES_ENDPOINT =
  "/v1/outages/icp-locations/{icpNumber}/planned-outages/future";

export class PlannedOutageService {
  static buildUrl = icpNumber => {
    if (icpNumber) {
      return ICP_PLANNED_OUTAGES_ENDPOINT.replace("{icpNumber}", icpNumber);
    }
    return ALL_PLANNED_OUTAGES_ENDPOINT;
  };

  static getPlannedOutages = async icpNumber => {
    // return mockResponse.PLANNED_OUTAGES;
    const { data } = await outageCentreClient.get(this.buildUrl(icpNumber));
    return data;
  };
}
