/* eslint-disable no-unused-vars,arrow-body-style */
import invariant from "invariant";
import client from "../client";
import { outageCentreClient } from "../client/client";

function byId(acc, outage) {
  return {
    ...acc,
    [outage.id]: outage,
  };
}

export class OutageService {
  static getOutageShapes = async () => {
    return Promise.all([
      client.get(`outagereporter/outages/shapes`),
      outageCentreClient.get(`/v1/outages/planned-outages/shapes`),
    ]).then(([oldBackend, newBackend]) => {
      const { data } = oldBackend;
      const plannedOutageData = newBackend.data;

      // Remove planned outage shapes from the data coming from the old backend
      data.features = data.features.filter(
        feature => feature.properties.outageType !== "Planned",
      );

      // Add in the planned outage shapes from the new backend
      plannedOutageData.features.forEach(feature =>
        data.features.push(feature),
      );
      return data;
    });
  };

  static createReport = function createReport({
    userAddress,
    details,
    access,
    notifications,
    registration,
  }) {
    // Create Outage Object
    let outageDetails = {
      addressId: userAddress,
    };

    /* istanbul ignore next */
    if (typeof details !== "undefined") {
      outageDetails = {
        ...outageDetails,
        issue: details.issue,
        other: details.other,
      };
    }

    /* istanbul ignore next */
    if (typeof access !== "undefined") {
      outageDetails = {
        ...outageDetails,
        hasDog: access.dog,
        lockedGate: access.lockedGate,
        meterInside: access.meterInside,
        contactOthers: access.contactOthers,
        contactName: access.contactName,
        contactPhone: access.contactPhone,
        extra: access.extra,
      };
    }

    // Create Subscription Object
    let subscriptionDetails = null;
    if (typeof notifications !== "undefined") {
      subscriptionDetails = {
        updateByTxt: notifications.byTxt,
        updateByEmail: notifications.byEmail,
      };
    }

    // Optional: Create registration object
    let registrationDetails = null;
    if (typeof registration !== "undefined") {
      registrationDetails = {
        name: registration.firstName,
        lastName: registration.lastName,
        phone: registration.phone,
        email: registration.email,
        updateByTxt: registration.updateByTxt,
        updateByEmail: registration.updateByEmail,
        password: registration.password,
      };
    }

    return client.post("outagereporter/icp-outages", {
      outageDetails,
      subscriptionDetails,
      registrationDetails,
    });
  };

  static saveSubscriptionPreferences({ outageId, icpNumber, channels }) {
    return client.post(`outagereporter/outages/${outageId}/icp-subscription`, {
      icpNumber,
      subscriptionDetails: channels,
    });
  }

  static getOutageEventHistory = function getOutageEventHistory(icpNumber) {
    // return new ServiceMock("getOutageEventHistory", [
    //   {
    //     byId: mockEventList.ALL_EVENTS.entities.events,
    //     allIds: mockEventList.ALL_EVENTS.result,
    //   },
    // ]);
    // eslint-disable-next-line no-unreachable
    return client
      .get(`/outagereporter/icp-locations/${icpNumber}/events`)
      .then(response => {
        const { data } = response;
        return {
          byId: data.entities.events,
          allIds: data.result,
        };
      });
  };

  // /outages/:outageId/tickets/resolve
  // /outages/:outageId/tickets/reopen
  static confirmOutageTicketStatus = (outageId, isResolved) => {
    invariant(
      typeof outageId === "string",
      "When updating a user's ticket status you must provide an `outageId`.",
    );
    invariant(
      typeof isResolved === "boolean",
      "When updating a user's ticket status you must provide the `isResolved` flag to indicate the reopening or resolving of the ticket",
    );

    // return new ServiceMock("confirmOutageTicketStatus", [
    //   [{ status: 200 }, 1000],
    // ]);

    return client.put(
      `outagereporter/outages/${outageId}/tickets/${
        isResolved ? "resolve" : "reopen"
      }`,
    );
  };

  static getSubscription(icpNumber) {
    // return new ServiceMock("getSubscription", [
    //   [{ status: 400, message: "This outage no longer exists." }],
    // ]);

    invariant(
      ["string", "number"].includes(typeof icpNumber),
      "Must provide a icp number ID when fetching subscription data",
    );
    return client.get(`outagereporter/address/${icpNumber}/icp-subscriptions`);
  }

  static getOutages() {
    // const data = MY_REPORTS_RESPONSE;
    // return new ServiceMock("getOutages", [
    //   {
    //     status: 200,
    //     data: {
    //       byId: data.reduce((acc, outage) => byId(acc, outage), {}),
    //       allIds: data.map(outage => outage.id),
    //     },
    //   },
    // ]);

    return client.get(`outagereporter/subscriptions/outages`).then(response => {
      const { data } = response;

      return {
        ...response,
        data: {
          byId: data.reduce((acc, outage) => byId(acc, outage), {}),
          allIds: data.map(outage => outage.id),
        },
      };
    });
  }
}
