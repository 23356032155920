import { useCallback, useState } from "react";
import moment from "moment";

export const usePlannedOutageDateToggle = plannedOutage => {
  const [isAlternateDateVisible, setAlternateDateVisible] = useState(false);

  const toggleDates = useCallback(() => {
    setAlternateDateVisible(!isAlternateDateVisible);
  }, [isAlternateDateVisible]);

  const status = plannedOutage.status.statusDescription;
  const isCancelled = status === "Cancelled";
  const isPostponed = status === "Postponed";

  const advertisedDateTimes = plannedOutage.plannedOutageBlocks.map(pob => {
    const startDate = pob.advertisedStartDateTime;
    const endDate = pob.advertisedEndDateTime;
    const isStriked = isCancelled || isPostponed;
    return {
      startDate,
      endDate,
      isStriked,
      type: "advertised",
    };
  });

  const alternateDateTimes = plannedOutage.plannedOutageBlocks.map(pob => {
    const startDate = moment.utc(pob.alternativeStartDateTime).toISOString();
    const endDate = moment.utc(pob.alternativeEndDateTime).toISOString();
    const isStriked = isCancelled;
    return {
      startDate,
      endDate,
      isStriked,
      type: "alternate",
    };
  });

  const hasAlternateDates = plannedOutage.plannedOutageBlocks.some(
    pob => !!pob.alternativeStartDateTime,
  );

  if ((hasAlternateDates && isCancelled) || isPostponed) {
    advertisedDateTimes.push(...alternateDateTimes);
  }

  const dateTimes = isAlternateDateVisible
    ? alternateDateTimes
    : advertisedDateTimes;

  const showToggleButton = hasAlternateDates && !isPostponed && !isCancelled;

  const toggleButtonLabel = isAlternateDateVisible
    ? "show original outage date(s) & time(s)"
    : "show alternative date(s) & time(s)";

  return [toggleButtonLabel, showToggleButton, toggleDates, dateTimes];
};
