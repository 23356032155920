import React from "react";
import PropTypes from "prop-types";
import { Flex, Box } from "rebass";
import { PlannedOutageCard } from "../PlannedOutageCard";

export const PlannedOutageCardList = ({ outages, ...props }) => (
  <Flex
    display="inline-block"
    flexDirection="column"
    flex="1 1 auto"
    justifyContent="space-between"
    alignItems="center"
    width={1}
    px={[2, null, 3]}
    data-testid="PlannedOutages"
    {...props}
  >
    {outages.map(outage => (
      <Box
        key={`${outage.switchingProposalNumber}-${outage.statusId}`}
        width={1}
        my={3}
        data-testid="PlannedOutages__row"
      >
        <PlannedOutageCard
          plannedOutage={outage}
          key={outage.switchingProposalNumber}
        />
      </Box>
    ))}
  </Flex>
);

PlannedOutageCardList.propTypes = {
  outages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PlannedOutageCardList;
