import axios from "axios";

// Optionally configure the axios instance here and bootstrap interceptors
const instance = axios.create({
  baseURL: "/",
  withCredentials: true,
});

export const outageCentreClient = axios.create({
  baseURL: "/",
  withCredentials: true,
});

export default instance;
