/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Flex, Box, Text } from "rebass";
import styled from "styled-components/macro";
import PlannedOutageDates from "./_/PlannedOutageDates";
import PlannedOutageStatus from "./_/PlannedOutageStatus";
import PlannedOutageEventInfo from "./_/PlannedOutageEventInfo";
import { DateFormat } from "../DateFormat";

const Container = styled(Box)`
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.14);
  background-color: ${props => props.theme.colors.light};
`;

const FlexContainer = styled(Flex)`
  flex: 1 1 auto;
  justify-content: flex-start;
`;

export const CANCELLED = "Cancelled";
export const POSTPONED = "Postponed";

const getChangeReasonPrefix = status => {
  switch (status) {
    case CANCELLED:
      return "Cancelled due to ";
    case POSTPONED:
      return "Postponed due to ";
    default:
      return "";
  }
};

const PlannedOutageChangeReason = ({ status, changeReason }) =>
  getChangeReasonPrefix(status) + changeReason;

export const PlannedOutageCard = ({ plannedOutage, ...props }) => (
  <Container
    px={[3, 3, 4]}
    py={4}
    {...props}
    data-testid={`planned-outage-${plannedOutage.switchingProposalNumber}`}
  >
    <Flex flexDirection="column">
      <Box>
        <FlexContainer>
          <Box width={[0.6, 0.7]}>
            <PlannedOutageEventInfo
              label="reference"
              fontWeight="bold"
              contentMargin={1}
            >
              <Text as="p" m={0} data-testid="reference">
                {plannedOutage.switchingProposalNumber}
              </Text>
            </PlannedOutageEventInfo>
          </Box>

          <Box ml="auto">
            <PlannedOutageStatus
              status={plannedOutage.status.statusDescription}
            />
          </Box>
        </FlexContainer>
      </Box>

      <Box mt={3}>
        <Text as="p" m={0} fontSize={4} fontWeight="bold" data-testid="suburb">
          {plannedOutage.suburb}
        </Text>
      </Box>

      <PlannedOutageDates plannedOutage={plannedOutage} />

      <Box mt={4}>
        <PlannedOutageEventInfo
          label="type of work"
          fontWeight="bold"
          contentMargin={1}
        >
          <Text as="p" m={0} data-testid="type-of-work">
            {plannedOutage.changeReason.reasonDescription}
          </Text>
        </PlannedOutageEventInfo>
      </Box>

      {plannedOutage.cancelledReason && (
        <Box mt={3}>
          <PlannedOutageEventInfo
            label="reason for change"
            fontWeight="bold"
            contentMargin={1}
          >
            <Text as="p" m={0} data-testid="change-reason">
              <PlannedOutageChangeReason
                status={plannedOutage.status.statusDescription}
                changeReason={
                  plannedOutage.cancelledReason.changeReasonDescription
                }
              />
            </Text>
          </PlannedOutageEventInfo>
        </Box>
      )}

      {!plannedOutage.cancelledReason && plannedOutage.postponedReason && (
        <Box mt={3}>
          <PlannedOutageEventInfo
            label="reason for change"
            fontWeight="bold"
            contentMargin={1}
          >
            <Text as="p" m={0} data-testid="change-reason">
              <PlannedOutageChangeReason
                status={plannedOutage.status.statusDescription}
                changeReason={
                  plannedOutage.postponedReason.changeReasonDescription
                }
              />
            </Text>
          </PlannedOutageEventInfo>
        </Box>
      )}

      <Box mt={3} alignSelf="flex-end">
        <Text as="p" m={0} fontSize={0} data-testid="last-updated">
          <b>last updated </b>
          <DateFormat time={plannedOutage.updatedAt} format="h:mma DD/MM/YY" />
        </Text>
      </Box>
    </Flex>
  </Container>
);

PlannedOutageCard.propTypes = {
  plannedOutage: PropTypes.shape({
    switchingProposalNumber: PropTypes.string,
    status: PropTypes.object,
    suburb: PropTypes.string,
    changeReason: PropTypes.object,
    updatedAt: PropTypes.string,
  }).isRequired,
};

export default PlannedOutageCard;
