/* eslint-disable no-param-reassign */

export const axiosApiConfiguration = (
  client,
  apikey,
  apiBaseUrl,
) => () => next => action => {
  function configureAxiosInstance() {
    // Configure the client instance
    client.defaults = client.defaults || {};
    client.defaults.baseURL = apiBaseUrl;
    client.defaults.headers = {
      ...client.defaults.headers,
      apikey,
    };
  }

  configureAxiosInstance();
  return next(action);
};

export const axiosInterceptorConfiguration = (
  client,
  interceptors = {},
) => store => next => {
  Object.entries(interceptors).forEach(([type, list]) => {
    const isValidType = ["request", "response"].includes(type);

    if (isValidType && Array.isArray(list)) {
      list.forEach(interceptor => {
        let successHandler;
        let errorHandler;

        if (Array.isArray(interceptor)) {
          // Note that our interceptors are functions that take our redux store as the only argument
          successHandler =
            typeof interceptor[0] === "function"
              ? interceptor[0](store)
              : /* istanbul ignore next */ r => r;

          errorHandler =
            typeof interceptor[1] === "function"
              ? interceptor[1](store)
              : /* istanbul ignore next */ r => r;
        } else {
          successHandler = interceptor(store);
        }

        // Guard against interceptors not being available in tests
        if (client && client.interceptors) {
          client.interceptors[type].use(successHandler, errorHandler);
        }
      });
    }
  });

  return action => next(action);
};
