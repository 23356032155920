import { handleActions } from "redux-actions";
import {
  plannedOutagesRequest,
  plannedOutagesSuccess,
  plannedOutagesFailure,
  clearPlannedOutages,
} from "./planned-outages.actions";

export const INITIAL_STATE = {
  isFetching: false,
  outages: [],
  error: undefined,
};

export const reducer = handleActions(
  {
    [plannedOutagesRequest]: state => ({
      ...state,
      isFetching: true,
      error: undefined,
    }),
    [plannedOutagesSuccess]: (state, action) => ({
      ...state,
      isFetching: false,
      outages: action.payload,
      error: undefined,
    }),
    [clearPlannedOutages]: state => ({
      ...state,
      ...INITIAL_STATE,
    }),
    [plannedOutagesFailure]: (state, action) => ({
      ...state,
      isFetching: false,
      outages: [],
      error: action.payload,
    }),
  },
  INITIAL_STATE,
);

export default reducer;
